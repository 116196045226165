import '../bin/_jquery';
import 'superfish';

class Header {
    static Superfish() {
        $('.nav').superfish({
            animation: {height: 'show'},
            animationOut: {height: 'hide'},
            speed: 'fast',
        });
    }

    static MobileMenuToggle() {
        $('.js-mobile-menu-toggle').on(
            'click',
            e => {
                e.preventDefault();

                $('body').toggleClass('menu-active');
            }
        )
    }
}

export default Header;
