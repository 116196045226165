import '../bin/_jquery';
import 'slick-carousel';

class Carousel {
    static SupportersCarousel () {
        $('.js-supporters-carousel').slick(
            {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                prevArrow: `<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></button>`,
                nextArrow: `<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></button>`,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        );
    }
}

export default Carousel;
